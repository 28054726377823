import { FuelPriceRequest } from "./FuelProceRequest";
import { ServiceStationPriceRespond } from "./ServiceStationPriceRespond";

export interface IServiceStationRespond
{
    Id: string;
    ShortId: number;
    ServiceStationName: string;
    Address: string;
    Suburb: string;
    Postcode: string;
    Brand: string;
    State: string;
    Longitute: number;
    Latitude: number;
    Url: string;
    PetrolPricesList: ServiceStationPriceRespond[];
    FuelPriceRequest: FuelPriceRequest[];    
}
export class ServiceStationRespond implements IServiceStationRespond
{
    Id: string = "";
    ShortId: number = 0;
    ServiceStationName: string = "";
    Address: string = "";
    Suburb: string = "";
    Postcode: string = "";
    Brand: string = "";
    State: string = "";
    Longitute: number = 0;
    Latitude: number = 0;
    Url: string = "";
    PetrolPricesList: ServiceStationPriceRespond[] = [];
    FuelPriceRequest: FuelPriceRequest[] = [];
    
    // constructor(id:string, shortId:number, serviceStationName: string, address:string, suburb:string, postcode:string, brand:string, state:string,longitute: number, latitude: number)
    // {
    //     this.Id = id;
    //     this.ShortId = shortId;
    //     this.ServiceStationName = serviceStationName;
    //     this.Address = address;
    //     this.Suburb = suburb;
    //     this.Postcode = postcode;
    //     this.Brand = brand;    
    //     this.State = state;    
    //     this.Longitute = longitute;
    //     this.Latitude = latitude;
    // }
    convertResponseToRequest(): FuelPriceRequest[]
    {
        this.FuelPriceRequest = [];

        this.PetrolPricesList.forEach((item) => {

            let newData = new FuelPriceRequest();
            newData.Active = "Y";
            newData.CreateDatetime = new Date().toISOString();
            newData.CreateOperator = "pjb";
            newData.Price = item.Price;
            newData.PriceUpdateDate = new Date().toISOString();
            newData.FuelType = item.PetrolType;
            newData.ServiceStationId = this.Id;
            this.FuelPriceRequest.push(newData)

        });

        return this.FuelPriceRequest;

    }
    isPetrolPricesListValid()
    {
        //check that they all have price type and value, let it go
        if(this.PetrolPricesList)
        {
            let empty_exist = this.PetrolPricesList.filter(p => !p.PetrolType || p.Price <= 0).length > 1;
            return !empty_exist;         
        }
        return false;
    }
    populateFromJson(data:any) : void
    {
        if(data)
        {
            this.Id = data.id;
            this.ShortId = data.shortId;
            this.ServiceStationName = data.serviceStationName;
            this.Address = data.address;
            this.State = data.state;
            this.Suburb = data.suburb;
            this.Postcode = data.postcode;
            this.Brand = data.brand;
            this.Longitute = data.longitute;
            this.Latitude = data.latitude;
            this.Url = data.url;

            this.PetrolPricesList = [];
            if(data.petrolPricesList)
            {               
                data.petrolPricesList.forEach((element:any) => {
                    
                    let price = new ServiceStationPriceRespond();
                    price.populateServiceStationPriceRespond(element);
                    this.PetrolPricesList.push(price);
                });
            }
            
        }
    }
    setPetrolPriceList(petrolPricesList:ServiceStationPriceRespond[]) : void
    {
        this.PetrolPricesList = petrolPricesList;
    }
}