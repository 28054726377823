import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonPage, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { chevronForwardCircle, closeCircleOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { APIResponse } from "../../Models/APIResponse";
import { SuburbPostcodeRespond } from "../../Models/SuburbPostcodeRespond";
import './modal-location.css';

export const ModalLocation: React.FC = () => {

    const [selecteText, setSelecteText] = useState<string>();
    const [selecteValue, setselecteValue] = useState<string>();
    const [isCloseHidden,setIsCloseHidden] = useState<boolean>(false);
    const [result, setResult] = useState<SuburbPostcodeRespond[]>();
    const [resultFiltered, setResultFiltered] = useState<SuburbPostcodeRespond[]>();
    const [resultAll, setResultAll] = useState<SuburbPostcodeRespond[]>();
    
    const nextButton = useRef<HTMLElement>(null);
    // const el = useRef<HTMLElement>(null);
    const searchElement = useRef<HTMLIonSearchbarElement>(null);


    useEffect(() => {

        console.log("modal-location USE EFFECT");

        if(searchElement && searchElement.current)
        {
            searchElement.current.focus();
        }

        searchElement.current?.addEventListener("ionInput",async (e) => {
   
          HideNextButton();
          const query = (e.target as HTMLTextAreaElement).value.toLowerCase();    
            if(query.length > 0)
            {
              await findAllSuburbPostcode(query);
            }    
        });   

    },[])



    function ShowNextButton()
    {
        if(nextButton &&  nextButton!.current)
        {
            nextButton!.current!.hidden = false;
        }
      //this.nextButton.style.display = "block";
    }
    function HideNextButton()
    {
        if(nextButton &&  nextButton!.current)
        {
            nextButton!.current!.hidden = true;
        }
      //this.nextButton.style.display = "none";
    }
    function onIonItemClick(event: UIEvent) {  
     
      let selecteText:string = (event.currentTarget as HTMLButtonElement).dataset.description ?? "";
      let selecteValue:string = (event.currentTarget as HTMLButtonElement).dataset.postcode ?? "";
      
      setSelecteText(selecteText);
      setselecteValue(selecteValue);
            
      ShowNextButton();
  
    }
    function onOkClick()
    {
      dismiss(selecteText);
    }
    function dismiss(data?: any) {
    
        // dismiss the closest modal and optionally pass back data
        (searchElement!.current!.closest('ion-modal') as any).dismiss({
          'dismissed': true,
          'data': data
        });
    }
    async function findAllSuburbPostcode(value:string) {
        let response = await fetch((window as any).env.API_SERVER+'/api/suburbs/all/' + value + '/30');    
        let json = await response.json();        
        let spr: SuburbPostcodeRespond[] = [];
        json.resultData.forEach((element:any) => {
          
          spr.push(new SuburbPostcodeRespond(element.suburb,element.postcode,element.description,element.state))
        
        })

        setResultFiltered(spr);
        setResult(spr);
    }

    return (
        <IonPage>
        <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Which area are you looking at?</IonTitle>
          <IonIcon slot="end" color="light" hidden={isCloseHidden} onClick={onOkClick.bind(this)} size="large" icon={closeCircleOutline}></IonIcon>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar ref={searchElement} id="ionSearch" placeholder="Type in a Suburb or Postcode. e.g: Parramatta or 2150"></IonSearchbar>         
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonList id="listLocationSuburb">
          {
            result?.map((element) => {
              return (
                <IonItem key={element.Description} data-postcode={element.Postcode} data-description={element.Description} onClick={(e)=>{onIonItemClick(e as any)}} button>{element.Description}</IonItem>
              );
            })
          }
        </IonList>
      </IonContent>
      <IonFooter id="nextFooter">        
      <IonToolbar color="light">
        <IonButton id="btnOk" ref={nextButton as any} color="success" onClick={onOkClick.bind(this)} expand="block">          
          Take me to {selecteText} 
          <IonIcon  icon={chevronForwardCircle} size="large" slot="end"></IonIcon>  
        </IonButton>        
      </IonToolbar>
    </IonFooter>
    </IonPage>
    );
  };

export default ModalLocation

