import {
  createAnimation,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Map.css";
import { globalService } from "../shared/sharedService";
import {
  businessOutline,
  locationOutline,
  searchOutline,
  closeCircleOutline,
  pinOutline,
  refreshCircle,
  refreshCircleOutline,
  refreshOutline,
  refreshSharp,
  refreshCircleSharp,
} from "ionicons/icons";
import { modalController } from "@ionic/core";
import ModalLocation from "../components/modal-location/modal-location";
import { ServiceStationRespond } from "../Models/ServiceStationRespond";
import { ServiceStationPriceRespond } from "../Models/ServiceStationPriceRespond";
import { defaultCipherList } from "constants";
import { useHistory } from "react-router-dom";

const Map: React.FC = () => {
  const history = useHistory();
  const google_marker_template: string = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" data-stacking-context="true" aria-owns="marker-template" width="640" height="40" viewBox="0 785 640 40">
  <!-- Generated by dom-to-svg from  -->    <style />
  
  <g data-tag="div" id="marker-template" data-z-index="auto" data-stacking-context="true" aria-owns="ion-margin-start1">
      <g data-tag="ion-chip" id="ion-margin-start1" class="ion-margin-start md ion-activatable" data-z-index="auto" data-stacking-context="true" mask="url(#mask-for-ion-margin-start11)" aria-owns="md1 sc-ion-label-md-h1">
          <g data-stacking-layer="rootBackgroundAndBorders">
              <rect width="102.375" height="32" x="16" y="789" fill="rgba(0, 0, 0, 0.12)" rx="16" ry="16" />
          </g>
          <mask id="mask-for-ion-margin-start11">
              <rect width="102.375" height="32" x="16" y="789" fill="#ffffff" />
          </mask>
          <g data-tag="ion-avatar" id="md1" class="md" data-z-index="auto" data-stacking-context="true" aria-owns="img1">
              <g data-tag="img" id="img1" data-z-index="auto" data-stacking-context="true" role="img" mask="url(#mask-for-img11)">                  
                  <mask id="mask-for-img11">
                      <rect width="24" height="24" x="20" y="793" fill="#ffffff" />
                  </mask>
                  <image id="img1-image" xlink:href="" x="20" y="793" width="24" height="24" />
              </g>
          </g>
          <g data-tag="ion-label" id="sc-ion-label-md-h1" class="sc-ion-label-md-h sc-ion-label-md-s md" data-z-index="auto" data-stacking-context="true" aria-owns="micro1">
              <g data-tag="ion-badge" id="micro1" class="micro ion-color ion-color-danger md" data-z-index="auto" data-stacking-context="true">
                  <g data-stacking-layer="rootBackgroundAndBorders">
                      <rect width="54.375" height="16" x="52" y="795.5" fill="*color*" rx="4" ry="4" />
                  </g>
                  <text color="rgb(255, 255, 255)" dominant-baseline="text-after-edge" font-family="Roboto, &quot;Helvetica Neue&quot;, sans-serif" font-size="9px" font-stretch="100%" font-style="normal" font-variant="normal" font-weight="700" direction="ltr" letter-spacing="normal" text-decoration="none solid rgb(255, 255, 255)" text-anchor="start" text-rendering="optimizelegibility" unicode-bidi="normal" word-spacing="0px" writing-mode="horizontal-tb" user-select="none" fill="rgb(255, 255, 255)">
                      <tspan xml:space="preserve" x="56" y="808.5" lengthAdjust="spacingAndGlyphs">*price*</tspan>
                  </text>
              </g>111
          </g>
      </g>
  </g>
</svg>`;
  //   const chip_template = `<g data-tag="ion-badge" id="ion-badge-*color*" class="micro ion-color ion-color-*color* md" data-z-index="auto" data-stacking-context="true">
  //                           <g data-stacking-layer="rootBackgroundAndBorders">
  //                               <rect width="*border-width*" height="16" x="*border-x*" y="797" fill="*color*" rx="4" ry="4" />
  //                           </g>
  //                           <text color="rgb(255, 255, 255)" dominant-baseline="text-after-edge" font-family="Roboto, &quot;Helvetica Neue&quot;, sans-serif" font-size="9px" font-stretch="100%" font-style="normal" font-variant="normal" font-weight="700" direction="ltr" letter-spacing="normal" text-decoration="none solid rgb(255, 255, 255)" text-anchor="start" text-rendering="optimizelegibility" unicode-bidi="normal" word-spacing="0px" writing-mode="horizontal-tb" user-select="auto" fill="rgb(255, 255, 255)">
  //                               <tspan xml:space="preserve" x="*text-x*" y="810" lengthAdjust="spacingAndGlyphs">*text*</tspan>
  //                           </text>
  //                         </g>`;
  const [title, setTitle] = useState((window as any).env.APP_NAME);
  // const [modal, setModal] = useRef<Component>(null)
  const [present, dismiss] = useIonModal(ModalLocation, {
    isCloseHidden: true,
  });
  const [selectedServiceStation, setSelectedServiceStation] =
    useState<ServiceStationRespond>();
  const [selectedPriceType, setSelectedPriceType] = useState<string>("E10");
  const [iconMarkerList, setIconMarkerList] = useState<google.maps.Marker[]>(
    []
  );
  const [markerList, setMarkerList] = useState<google.maps.Marker[]>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const [dataCollection, setDataCollection] =
    useState<ServiceStationRespond[]>();
  const [currentLocation, setCurrentLocation] = useState<google.maps.Marker>();
  const [locationName, setLocationName] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, SetIsLoading] = useState(false);
  const [selectedStationId, setSelectedStationId] = useState<string>("");
  const [presentToast, dismissToast] = useIonToast();
  // const [selectedStationName,setSelectedStationName] = useState<string>("");
  // const [selectedStationAddress,setSelectedStationAddress] = useState<string>("");
  // const [selectedStationBrand,setSelectedStationBrand] = useState<string>("");
  // const [selectedStationState,setSelectedStationState] = useState<string>("");
  // const [selectedPetrolPrice, setSelectedPetrolPrice] = useState<ServiceStationPriceRespond[]>();
  let activeInfoWindow: google.maps.InfoWindow;
  let btnCloseInfo: HTMLElement;
  let showAnimation = createAnimation()
    .addElement(document.getElementById("footerDetails")!)
    .duration(1000)
    .beforeStyles({
      opacity: 1,
      display: "block",
      height: "0px",
      transition: "height .5s",
    })
    .afterStyles({
      opacity: 1,
      display: "block",
      height: "91px",
      transition: "height .5s",
    });
  let hideAnimation = createAnimation()
    .addElement(document.getElementById("footerDetails")!)
    .duration(1000)
    .beforeStyles({
      opacity: 1,
      display: "block",
      height: "91px",
      transition: "height .5s",
    })
    .afterStyles({
      opacity: 1,
      display: "block",
      height: "0px",
      transition: "height .5s",
    });
  hideAnimation.play();
  const _FuelType = [
    {
      selected: true,
      value: "E10",
      display: "E10",
    },
    {
      selected: false,
      value: "P98",
      display: "P98",
    },
    {
      selected: false,
      value: "P95",
      display: "P95",
    },
    {
      selected: false,
      value: "E85",
      display: "E85",
    },
    {
      selected: false,
      value: "PDL",
      display: "PDL",
    },
    {
      selected: false,
      value: "DL",
      display: "DL",
    },
    {
      selected: false,
      value: "U91",
      display: "U91",
    },
    {
      selected: false,
      value: "B20",
      display: "B20",
    },
    {
      selected: false,
      value: "CNG",
      display: "CNG",
    },
    {
      selected: false,
      value: "EV",
      display: "EV",
    },
    {
      selected: false,
      value: "LNG",
      display: "LNG",
    },
    {
      selected: false,
      value: "LPG",
      display: "LPG",
    },
  ];

  const [defaultFuelType, setDefaultFuelType] = useState<string[]>(
    _FuelType.filter((p) => p.selected).map((e) => e.value)
  );

  useEffect(() => {
    initMap();

    if (
      document.cookie.indexOf("application='servoaid'") <= 0 &&
      document.cookie.indexOf("accepted=Yes") <= 0
    ) {
      OnLoadAlert();
    }
  }, []);
  const setCurrentPosition = (position: any) => {
    if (position && position.coords) {
      globalService.RECENT_LONGITUTE = position.coords.longitude;
      globalService.RECENT_LATITUTE = position.coords.latitude;

      centerMap(globalService.RECENT_LATITUTE, globalService.RECENT_LONGITUTE);

      //set the current location and postcode
      var geocoder = new google.maps.Geocoder();

      //Find the coordinates suburb
      var loc = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      geocoder.geocode({ location: loc }, (results) => {
        results!.forEach((element) => {
          element.types.forEach((type) => {
            if (type === "postal_code") {
              //console.log(element);
              //isAddress = true;
              globalService.RECENT_LOCATION = element.formatted_address;
              setLocationName(globalService.RECENT_LOCATION);
              executeSearch();
              return;
            }
          });
        });
      });
    }
  };
  var OnLoadAlert = () => {
    presentToast({
      color: "primary",
      buttons: [
        {
          text: "I accept",
          handler: () => {
            document.cookie =
              "application=servoaid accepted=Yes acceptedDate=" +
              Date.now().toString();
            dismissToast();
          },
        },
      ],
      header: "This website uses cookies",
      message:
        "Our site uses cookies and similar technologies to recognise your repeat visits and preferences, as well as to measure the effectiveness of campaigns and analyse traffic. By clicking 'I accept' on this banner, or using our site, you consent to the use of cookies unless you have disabled them",
      position: "bottom",
    });
  };
  const positionError = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.error("User denied the request for Geolocation.");
        break;

      case error.POSITION_UNAVAILABLE:
        console.error("Location information is unavailable.");
        break;

      case error.TIMEOUT:
        console.error("The request to get user location timed out.");
        break;

      case error.UNKNOWN_ERROR:
        console.error("An unknown error occurred.");
        break;
    }
  };

  useEffect(() => {
    if (selectedPriceType) {
      executeSearch();
    }
  }, [selectedPriceType]);

  useEffect(() => {
    if (map) {
      navigator.geolocation.getCurrentPosition(
        setCurrentPosition,
        positionError,
        {
          enableHighAccuracy: false,
          timeout: 15000,
          maximumAge: 0,
        }
      );
    }
  }, [map]);

  useEffect(() => {
    if (dataCollection && dataCollection.length > 0) {
      populateMap();
    }
  }, [dataCollection]);

  const centerMap = (lat: number, long: number) => {
    let latLng = new google.maps.LatLng(lat, long);
    if (map) {
      map.panTo(latLng);

      if (!currentLocation) {
        //Create an instance of an marker
        setCurrentLocation(
          new google.maps.Marker({
            position: latLng,
            icon: "/assets/icon/person_pin.png",
            map: map,
            title: "You",
            animation: google.maps.Animation.BOUNCE,
          })
        );
      } else {
        //Set the current instance position
        currentLocation.setPosition(latLng);
      }
    }
  };
  const initMap = () => {
    let mapOptions = {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 15,
      zoomControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,
    };

    if (!map) {
      var mm = new google.maps.Map(
        document.getElementById("google-map-container") as any,
        mapOptions
      );

      mm.addListener("zoom_changed", () => {
        // debounce(executeSearch, 1000);
      });

      mm.addListener("idle", () => {
        debounce(() => {executeSearch();}, 1000);
      });

      setMap(mm);
    }
  };
  const populateMap = () => {
    SetIsLoading(true);
    clearMarker();
    clearMarkerIcon();

    if (dataCollection) {
      dataCollection.forEach((item) => {
        createMarker(item);
      });

      SetIsLoading(false);
    }
  };
  const clearMarker = () => {
    if (markerList && markerList.length > 0) {
      markerList.forEach((k: google.maps.Marker) => {
        k.setMap(null);
      });
    }
  };
  const clearMarkerIcon = () => {
    if (iconMarkerList && iconMarkerList.length > 0) {
      iconMarkerList.forEach((k: google.maps.Marker) => {
        k.setMap(null);
      });
    }
  };
  const createMarker = async (data: ServiceStationRespond) => {
    if (data) {
      let latLng = new google.maps.LatLng(
        Number.parseFloat(data.Latitude.toString()),
        Number.parseFloat(data.Longitute.toString())
      );
      let color = "";
      let svg_body_marker = "";
      let price = data.PetrolPricesList.find(
        (p) => p.PetrolType === selectedPriceType
      );
      if (!price) {
        return;
      }
      color = price!.Colour;
      svg_body_marker = google_marker_template.replace(
        "*color*",
        price!.PriceColour
      );
      svg_body_marker = svg_body_marker.replaceAll(
        "*price*",
        price?.PetrolType + ":" + price?.Price
      );

      let newMarker = new google.maps.Marker({
        position: latLng,
        icon: {
          size: new google.maps.Size(120, 36),
          url: "data:image/svg+xml;base64," + window.btoa(svg_body_marker),
        },
        // label: data.ServiceStationName,
        map: map,
        title: data.ServiceStationName,
        optimized: false,
        zIndex: price?.PriceIndex,
      });

      newMarker.set("data-id", data.Id);

      let newIconMarker = new google.maps.Marker({
        position: latLng,
        icon: {
          // size: new google.maps.Size(32, 32),
          scaledSize: new google.maps.Size(32, 32),
          // origin: new google.maps.Point(0,10),
          url:
            (window as any).env.API_SERVER +
            "/api/Images/image?url=" +
            data.Url,
          // url: data.Url,
          anchor: new google.maps.Point(50, 33),
        },
        // label: data.ServiceStationName,
        map: map,
        // title: data.ServiceStationName,
        optimized: false,
        zIndex: price?.PriceIndex,
      });

      let infowindow = new google.maps.InfoWindow({
        content:
          `
        <ion-card id="infowindowcards" key=` +
          data.Id +
          `>
          <ion-item lines="full">           
             <label slot="start" class="small">` +
          data.ServiceStationName +
          `</label>
             <ion-icon slot="end" color="danger"  id="btnCloseInfo" icon="` +
          closeCircleOutline +
          `"></ion-icon>
          </ion-item>
          <ion-item>              
            <ion-badge color="` +
          color +
          `">` +
          price.PetrolType +
          ": " +
          price.FormattedPrice +
          `</ion-badge>          
          </ion-item>
          <ion-item>  
          <ion-badge color="success" class="micro micro-skinny">Low</ion-badge>&nbsp;
          <ion-badge color="warning" class="micro micro-skinny">Mid</ion-badge>&nbsp;
          <ion-badge color="danger" class="micro micro-skinny">High</ion-badge>
          </ion-item>                
        </ion-card>`,
      });

      let onMarkClick = () => {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infowindow.open(map, newMarker);
        activeInfoWindow = infowindow;
        let id = newMarker.get("data-id");
        setSelectedStationId(id);

        setSelectedServiceStation(dataCollection?.find((p) => p.Id === id));

        // hideAnimation.play();
        showAnimation.play();
      };
      newMarker.addListener("click", onMarkClick);

      //Add to global variable
      setMarkerList((prev) => [...prev, newMarker]);
      setIconMarkerList((prev) => [...prev, newIconMarker]);

      //wait till the info windows is ready and attache the function
      google.maps.event.addListener(infowindow, "domready", function () {
        //Assign to the global variable
        btnCloseInfo = document.getElementById("btnCloseInfo")!;

        if (btnCloseInfo) {
          //Attach a click Event
          btnCloseInfo.addEventListener("click", () => {
            //Close the info box
            infowindow.close();
            hideAnimation.play();
          });
        }

        //Now because we don't have a PARENT selector, we have to do it by javascript
        var cards = document.getElementsByTagName("ion-card");
        // console.log(cards);
        Array.from(cards).forEach((e) => {
          // console.log(e.parentElement.parentElement);
          //e.parentElement.classList.add("transparent-background");
          (e as any).parentElement.parentElement.classList.add(
            "transparent-background"
          );
          (e as any).parentElement.parentElement.parentElement.classList.add(
            "transparent-background"
          );
          //e.parentElement.parentElement.parentElement.parentElement.classList.add("transparent-background")
        });
      });
    }
  };
  const getMapRadius = (): number => {
    if (!map) {
      return 5;
    }
    var bounds = map.getBounds();

    var center = bounds!.getCenter();
    var ne = bounds!.getNorthEast();

    // r = radius of the earth in statute miles
    var r = 3963.0;

    // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
    var lat1 = center.lat() / 57.2958;
    var lon1 = center.lng() / 57.2958;
    var lat2 = ne.lat() / 57.2958;
    var lon2 = ne.lng() / 57.2958;

    // distance = circle radius from center to Northeast corner of bounds
    var dis =
      r *
      Math.acos(
        Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
      );
    return Number.parseInt(dis.toString()) + 2;
  };
  // const refresh = (clear: boolean = false) => {
  //   //if (clear) {
  //   //this.clearMarker();
  //   executeSearch();
  //   //}

  //   //Search
  // };
  const onFuelTypeChanged = (e: any) => {
    // setDefaultFuelType([e.detail.value]);
    setSelectedPriceType(e.detail.value);
  };

  const presentLocationModal = async (isCloseHidden: boolean = true) => {
    let m = present({
      showBackdrop: true,
      keyboardClose: true,
      backdropDismiss: false,
      presentingElement: await modalController.getTop(),
      onDidDismiss: OnLocationDidDismiss,
    });
  };
  const executeSearch = () => {
    globalService.RECENT_LONGITUTE =
      map?.getCenter()?.lng() ?? globalService.RECENT_LONGITUTE;
    globalService.RECENT_LATITUTE =
      map?.getCenter()?.lat() ?? globalService.RECENT_LATITUTE;
    let rad = getMapRadius();

    console.log("Executing search...");
    console.log("LONG:" +  globalService.RECENT_LONGITUTE);
    console.log("LAT:" + globalService.RECENT_LATITUTE);
    //Do a search
    search(
      "",
      "",
      globalService.RECENT_LONGITUTE,
      globalService.RECENT_LATITUTE,
      rad
    );
  };
  const OnLocationDidDismiss = (data: any) => {
    //console.log("Selected Data: " + data.data.data);
    if (
      data &&
      data.detail.data.data &&
      data.detail.data &&
      data.detail.data.data
    ) {
      let param = data.detail.data.data;
      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: param }, (results: any, status) => {
        if (status === "OK") {
          let location = results[0].geometry.location;
          let formatted_test = results[0].formatted_address;
          globalService.RECENT_LONGITUTE = location.lng();
          globalService.RECENT_LATITUTE = location.lat();
          globalService.RECENT_LOCATION = formatted_test;

          setLocationName(formatted_test);

          setCurrentPosition({
            coords: {
              longitude: globalService.RECENT_LONGITUTE,
              latitude: globalService.RECENT_LATITUTE,
            },
          });

          executeSearch();
        } else {
          alert("Something went wrong..." + status);
        }
      });
    }
  };
  const search = async (
    keywords: string,
    location: string,
    longitude: number,
    latitude: number,
    distance: number
  ) => {
    if (!longitude && !latitude) {
      return;
    }

    SetIsLoading(true);

    let response = await fetch(
      (window as any).env.API_SERVER +
        "/api/ServiceStations/search?keywords=" +
        keywords +
        "&location=" +
        location +
        "&longitude=" +
        longitude +
        "&latitude=" +
        latitude +
        "&distance=" +
        distance
    );
    let json = await response.json();
    let ssr: ServiceStationRespond[] = [];
    json.resultData?.forEach((element: any) => {
      var n_ssr: ServiceStationRespond = new ServiceStationRespond();
      n_ssr.populateFromJson(element);
      ssr.push(n_ssr);
      SetIsLoading(false);
    });

    //Set State
    setDataCollection(ssr);
  };
  const onItemTap = (data: ServiceStationRespond) => {
    if (data) {
      let id = data.Id;
      history.push("servo/?id=" + id);
    }
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title} | Map</IonTitle>
          <IonProgressBar
            hidden={!isLoading}
            id="progressbar"
            color="danger"
            type="indeterminate"
          ></IonProgressBar>

          <IonButtons slot="end">
            <IonButton
              color="primary"
              onClick={() => {
                executeSearch();
              }}
            >
              <IonIcon slot="end" icon={refreshCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="light">
          <IonChip
            color="primary"
            onClick={() => {
              presentLocationModal(false);
            }}
          >
            <IonIcon icon={locationOutline} color="primary"></IonIcon>
            <IonLabel>Location: {locationName}</IonLabel>
            <IonIcon icon={searchOutline} color="secondary"></IonIcon>
          </IonChip>
          {/* <IonChip color="secondary">
            <IonIcon icon={businessOutline} color="secondary"></IonIcon>
            <IonLabel>Look for Station: {searchText}</IonLabel>
            <IonIcon icon={searchOutline} color="secondary"></IonIcon>
          </IonChip> */}
          <IonChip color="success">
            <IonLabel>Petrol Type</IonLabel>
            <IonSelect
              cancelText="Go Back"
              okText="Search"
              value={selectedPriceType}
              placeholder="Fuel Type"
              onIonChange={onFuelTypeChanged.bind(this)}
            >
              {_FuelType.map((element) => {
                return (
                  <span key={element.value}>
                    <IonSelectOption id={element.value} value={element.value}>
                      {element.value}
                    </IonSelectOption>
                  </span>
                );
              })}
            </IonSelect>
          </IonChip>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {(window as any).env.APP_NAME} | Map
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div id="google-map-container" className="google-map"></div>
      </IonContent>
      <IonFooter id="footerDetails" class="ion-no-border">
        <IonToolbar>
          <IonItem
            button
            color="light"
            key={`selected-${selectedServiceStation?.Id}`}
            detail
            data-id={selectedServiceStation?.Id}
            onClick={() => {
              onItemTap(selectedServiceStation!);
            }}
          >
            <IonAvatar slot="start">
              <img src={selectedServiceStation?.Url} />
            </IonAvatar>
            <IonLabel>
              <h2>
                <strong>{selectedServiceStation?.ServiceStationName}</strong>
              </h2>
              {selectedServiceStation?.PetrolPricesList.map((item) => {
                return (
                  <>
                    <IonBadge key={item.PetrolType} color={item.Colour}>
                      {item.PetrolType}: {item.Price}
                    </IonBadge>
                    &nbsp;&nbsp;
                  </>
                );
              })}
              <br />
              <IonIcon icon={locationOutline}></IonIcon>{" "}
              {selectedServiceStation?.Address}
            </IonLabel>
          </IonItem>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Map;

const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
