import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, helpOutline, listOutline, mapOutline, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Map';
import Tab2 from './pages/List';
import Tab3 from './pages/Help';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Help from './pages/Help';
import List from './pages/List';
import Map from './pages/Map';
import FuelPrice from './pages/fuel-price/FuelPrice';
import ServiceStation from './pages/service-station/ServiceStation';
import NotFound from './pages/nof-found/noteFound';
import TakePicture from './pages/take-picture/TakePicture';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/Map">
            <Map />
          </Route>
          <Route exact path="/List">
            <List />
          </Route>
          <Route path="/Help">
            <Help />
          </Route>
          <Route exact path="/">
            <Redirect to="/Map" />
          </Route>
          <Route path="/FuelPrice">
            <FuelPrice />
          </Route>
          <Route path="/Servo">
            <ServiceStation />
          </Route>
          <Route path="/TakePicture">
            <TakePicture />
          </Route>          
          <Route>
            <NotFound />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="Map" href="/map">
            <IonIcon icon={mapOutline} />
            <IonLabel>Map</IonLabel>
          </IonTabButton>
          <IonTabButton tab="List" href="/list">
            <IonIcon icon={listOutline} />
            <IonLabel>List</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Help" href="/help">
            <IonIcon icon={helpOutline} />
            <IonLabel>Help</IonLabel>
          </IonTabButton>
          {/* <IonTabButton tab="Fuel Price" href="/fuelprice?id=3f37a100-0754-42ab-a202-1c2c9f120d6d">
            <IonIcon icon={helpOutline} />
            <IonLabel>Fuel Price</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Servo" href="/servo?id=3f37a100-0754-42ab-a202-1c2c9f120d6d">
            <IonIcon icon={helpOutline} />
            <IonLabel>Servo</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Picture" href="/takepicture">
            <IonIcon icon={helpOutline} />
            <IonLabel>Picture</IonLabel>
          </IonTabButton> */}
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
