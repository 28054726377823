import { IonAvatar, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonProgressBar, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import ModalLocation from '../components/modal-location/modal-location';
import { modalController } from "@ionic/core";
import { globalService } from "../shared/sharedService";
import './List.css';
import { businessOutline, locationOutline, refreshCircleOutline, searchOutline } from 'ionicons/icons';
import { ServiceStationRespond } from '../Models/ServiceStationRespond';
import { useHistory } from 'react-router';

const List: React.FC = () => {
  const history = useHistory();
  const _FuelType = [
    {
      selected: true,
      value: "E10",
      display: "E10",
    },
    {
      selected: false,
      value: "P98",
      display: "P98",
    },
    {
      selected: false,
      value: "P95",
      display: "P95",
    },
    {
      selected: false,
      value: "E85",
      display: "E85",
    },
    {
      selected: false,
      value: "PDL",
      display: "PDL",
    },
    {
      selected: false,
      value: "DL",
      display: "DL",
    },
    {
      selected: false,
      value: "U91",
      display: "U91",
    },
    {
      selected: false,
      value: "B20",
      display: "B20",
    },
    {
      selected: false,
      value: "CNG",
      display: "CNG",
    },
    {
      selected: false,
      value: "EV",
      display: "EV",
    },
    {
      selected: false,
      value: "LNG",
      display: "LNG",
    },
    {
      selected: false,
      value: "LPG",
      display: "LPG",
    },
  ];
  const [isLoading, SetIsLoading] = useState(true);
  const [present, dismiss] = useIonModal(ModalLocation, {
    isCloseHidden: true,
  });
  const [searchText, setSearchText] = useState<string>("");
  const [locationName, setLocationName] = useState<string>(globalService.RECENT_LOCATION);
  const [selectedPriceType, setSelectedPriceType] = useState<string>("E10");
  const [dataCollection, setDataCollection] = useState<ServiceStationRespond[]>();
  
  const presentLocationModal = async (isCloseHidden: boolean = true) => {
    let m = present({
      showBackdrop: true,
      keyboardClose: true,
      backdropDismiss: false,
      presentingElement: await modalController.getTop(),
      onDidDismiss: OnLocationDidDismiss,
    });
  };
  const OnLocationDidDismiss = (data: any) => {
    //console.log("Selected Data: " + data.data.data);
    if (
      data &&
      data.detail.data.data &&
      data.detail.data &&
      data.detail.data.data
    ) {
      let param = data.detail.data.data;
      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: param }, (results: any, status) => {
        if (status === "OK") {
          let location = results[0].geometry.location;
          let formatted_test = results[0].formatted_address;
          globalService.RECENT_LONGITUTE = location.lng();
          globalService.RECENT_LATITUTE = location.lat();
          globalService.RECENT_LOCATION = formatted_test;
          setLocationName(formatted_test);  
          
          executeSearch(); 

        } else {
          alert("Something went wrong..." + status);
        }
      });
    }
  };
  const onFuelTypeChanged = (e: any) => {
    // setDefaultFuelType([e.detail.value]);
    setSelectedPriceType(e.detail.value);   
  };
  const onItemTap = (data:ServiceStationRespond) => {
    if(data)
    {
      let id = data.Id;
      history.push("servo/?id=" + id);
    }
  }
  const executeSearch = () => { 
          //Do a search
          search(
            searchText,
            '',
            globalService.RECENT_LONGITUTE,
            globalService.RECENT_LATITUTE,
            5 //default
          );
  }
  const search = async (
    keywords: string,
    location: string,
    longitude: number,
    latitude: number,
    distance: number
  ) => {
    SetIsLoading(true);

    let url =  (window as any).env.API_SERVER +
    "/api/ServiceStations/search?keywords=" +
    keywords +
    "&location=" +
    location +
    "&longitude=" +
    longitude +
    "&latitude=" +
    latitude +
    "&distance=" +
    distance
    let response = await fetch(url);
    let json = await response.json();
    let ssr: ServiceStationRespond[] = [];
    
    json.resultData?.forEach((element: any) => {
      var n_ssr: ServiceStationRespond = new ServiceStationRespond();    
      n_ssr.populateFromJson(element);
      ssr.push(n_ssr);
      SetIsLoading(false);
    });

    if(json.resultData && json.resultData.length == 0)
    {
      SetIsLoading(false);
    }   
    //Set State
    setDataCollection(ssr);
  };

  useEffect(() => {   
    if(dataCollection && dataCollection.length > 0)
    {
      console.log(dataCollection);      
    }    
  }, [dataCollection]);

  useEffect(() => {

    if(selectedPriceType)
    {
      executeSearch();
    }
  }, [selectedPriceType,locationName]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Servo Aid | List</IonTitle>
          <IonProgressBar
            hidden={!isLoading}
            id="progressbar"
            color="danger"
            type="indeterminate"
          ></IonProgressBar>
        </IonToolbar>
        <IonToolbar color="light">
          <IonChip
            color="primary"
            onClick={() => {
              presentLocationModal(false);
            }}
          >
            <IonIcon icon={locationOutline} color="primary"></IonIcon>
            <IonLabel>Location: {locationName}</IonLabel>
            <IonIcon icon={searchOutline} color="secondary"></IonIcon>
          </IonChip>
          {/* <IonChip color="secondary">
            <IonIcon icon={businessOutline} color="secondary"></IonIcon>
            <IonLabel>Look for Station: {searchText}</IonLabel>
            <IonIcon icon={searchOutline} color="secondary"></IonIcon>
          </IonChip> */}
          <IonChip color="success">
            <IonLabel>Petrol Type</IonLabel>
            <IonSelect
              cancelText="Go Back"
              okText="Search"
              value={selectedPriceType}
              placeholder="Fuel Type"
              onIonChange={onFuelTypeChanged.bind(this)}
            >
              {_FuelType.map((element) => {
                return (
                  <span key={element.value}>
                    <IonSelectOption id={element.value} value={element.value}>
                      {element.value}
                    </IonSelectOption>
                  </span>
                );
              })}
            </IonSelect>
          </IonChip>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={() => {executeSearch();}}>                
              <IonIcon slot="end" icon={ refreshCircleOutline }></IonIcon>
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Servo Aid | List</IonTitle>
          </IonToolbar>
        </IonHeader> 

        <IonList>
              { 
                  dataCollection?.map((item) => {
                    return <IonItem button color="light" data-id={item.Id} detail key={item.Id} onClick={() => { onItemTap(item); }}>
                    <IonAvatar slot="start">
                      <img src={item.Url} />
                    </IonAvatar>
                    <IonLabel>
                      <h2><strong>{item.ServiceStationName}</strong></h2>                
                      {
                        item?.PetrolPricesList.map((item) => {
                          return <><IonBadge key={item.PetrolType} color={item.Colour}>{item.PetrolType}: {item.Price}</IonBadge>&nbsp;&nbsp;</>
                        })
                      }
                      <br />
                      <IonIcon icon={locationOutline}></IonIcon> {item.Address}
                    </IonLabel>
                  </IonItem>
                  })
              }       
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default List;
