import {
  IonActionSheet, IonBackButton,
  IonBadge,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle, IonChip,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {
  businessOutline,
  callOutline,
  cameraOutline, closeCircleOutline,
  createOutline, locationOutline,
  navigateCircleOutline,
  pencilOutline
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ServiceStationRespond } from "../../Models/ServiceStationRespond";
import "./ServiceStation.css";

const ServiceStation: React.FC = () => {
  const _TITLE = "No Data";
  const [id, setId] = useState<string>();
  const [contact, setContact] = useState<string>("No Data");
  const [address, setAddress] = useState<string>("No Data");
  const history = useHistory();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [data, setData] = useState<ServiceStationRespond>();
  const location = useLocation();
  const [isLoading, SetIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get("id") || "");
  });

  useEffect(() => {
    if (id) {
      fetchData(id!);
    }
  }, [id,location]);

  const fetchData = async (id: string) => {
    SetIsLoading(true);
    let response = await fetch(
      (window as any).env.API_SERVER + "/api/ServiceStations/" + id
    );

    let json = await response.json();
    let n_ssr: ServiceStationRespond = new ServiceStationRespond();
    n_ssr.populateFromJson(json.resultData[0]);
    setData(n_ssr);
    SetIsLoading(false);
  };

  const onDial = (mobile: string) => {
    if (mobile) {
      window.open("tel:" + mobile);
    }
  };
  const onNavigate = (address: string) => {
    if (address) {
      window.open(
        "https://www.google.com/maps?saddr=My+Location&daddr=" +
          address +
          "&travelmode=driving",
        "_blank"
      );
    }
  };
  const onSubmitNewPrice = (id: string) => {
    if (id) {
      setShowActionSheet(true);
    }
  };
  const onTakePicture = () => {
    history?.push("/TakePicture");
  };
  const onEditPrice = () => {
    history?.push("/fuelprice?id=" + id);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{data?.ServiceStationName}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>    
          <IonProgressBar
            hidden={!isLoading}          
            id="progressbar"
            color="danger"
            type="indeterminate"
          ></IonProgressBar>     
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{data?.ServiceStationName}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardHeader>
            <IonItem lines="none" className="ion-no-padding">
              <IonThumbnail slot="start">
                <IonImg className="thumbnail" src={data?.Url} />
              </IonThumbnail>
              <IonLabel>
                <IonIcon icon={businessOutline}></IonIcon>{" "}
                {data?.ServiceStationName}
                
                {/* <IonCardSubtitle>
                  <IonIcon icon={callOutline}></IonIcon> {data?.Suburb}
                </IonCardSubtitle> */}
              </IonLabel>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding">
            <IonCardSubtitle>
                  <IonIcon icon={locationOutline}></IonIcon> {data?.Address}
                </IonCardSubtitle>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonFabButton
                      color="primary"
                      className="margin-auto"
                      onClick={() => {
                        onNavigate(address);
                      }}
                    >
                      <IonIcon icon={navigateCircleOutline}></IonIcon>
                    </IonFabButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonFabButton
                      color="primary"
                      className="margin-auto"
                      onClick={() => {
                        onDial(contact);
                      }}
                    >
                      <IonIcon icon={callOutline}></IonIcon>
                    </IonFabButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonFabButton
                      color="primary"
                      className="margin-auto"
                      onClick={() => {
                        onSubmitNewPrice("sdadasd");
                      }}
                    >
                      <IonIcon icon={createOutline}></IonIcon>
                    </IonFabButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="4"
                    className="ion-align-self-center ion-no-margin ion-no-padding"
                  >
                    <div className="ion-text-center">
                      <h5>Navigate</h5>
                    </div>
                  </IonCol>
                  <IonCol
                    size="4"
                    className="ion-align-self-center ion-no-margin ion-no-padding"
                  >
                    <div className="ion-text-center">
                      <h5>Call</h5>
                    </div>
                  </IonCol>
                  <IonCol
                    size="4"
                    className="ion-align-self-center ion-no-margin ion-no-padding"
                  >
                    <div className="ion-text-center">
                      <h5>Submit</h5>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </IonCardHeader>
        </IonCard>

        <IonCard>
          <IonCardContent>
            <IonList>
              <IonListHeader lines="inset">
                <IonLabel>Petrol Prices</IonLabel>
              </IonListHeader>

              {         
              data?.PetrolPricesList?.map((item) => {
                return (               
                    <IonItem key={item.PetrolType} lines="full" button>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="6">
                            <IonLabel>
                              <IonLabel>
                                <h2>{item.PetrolType}</h2>
                                <IonBadge color="light" className="micro-text">
                                  <IonText color="medium">
                                    {item.LastPriceUpdate}
                                  </IonText>
                                </IonBadge>
                              </IonLabel>
                            </IonLabel>
                          </IonCol>
                          <IonCol size="6" className="ion-text-end">
                            <IonLabel>
                              <IonChip color={item.Colour}>
                                <IonText color={item.Colour}>
                                  <strong>{item.Price}</strong>
                                </IonText>
                                &nbsp;
                                <IonBadge
                                  color={item.Colour}
                                  className="ion-no-margin micro-text"
                                >
                                  <IonIcon icon={item.ModalityIcon}></IonIcon>
                                  <IonText>{item.Modality}</IonText>
                                </IonBadge>
                              </IonChip>
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
               
                );
              })
              
              }
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonActionSheet
          subHeader="what would you like to do?"
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: "Take a picture",
              icon: cameraOutline,
              data: 10,
              handler: onTakePicture,
            },
            {
              text: "Enter manually",
              icon: pencilOutline,
              handler: onEditPrice,
            },
            {
              text: "Cancel",
              icon: closeCircleOutline,
              role: "cancel",
            },
          ]}
        ></IonActionSheet>
      </IonContent>
    </IonPage>
  );
};

export default ServiceStation;
