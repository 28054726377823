import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackCircle,
  arrowBackCircleOutline,
  arrowForward,
  arrowForwardCircle,
  arrowForwardCircleOutline,
  arrowForwardSharp,
  businessOutline,
  callOutline,
  caretDown,
  caretUp,
  createOutline,
  locationOutline,
  navigateCircle,
  navigateCircleOutline,
  pin,
} from "ionicons/icons";
import { useState } from "react";
import "./noteFound.css";
import { IonReactRouter } from '@ionic/react-router';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  const _TITLE = "Not Found!";

  const [contact, setContact] = useState<string>("02 9785 1548");
  const [address, setAddress] = useState<string>("1 charles st Parramatta 2150");
  const history =  useHistory();
 

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{_TITLE}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{_TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
   
        </IonCard>

        <IonCard>
          <IonCardContent>
            
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
