import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { Camera, CameraResultType } from "@capacitor/camera";
import "./TakePicture.css";
import { useDebugValue, useEffect, useRef, useState } from "react";
import { cameraOutline, checkmarkCircleOutline, mapOutline, pinOutline } from "ionicons/icons";
import { globalService } from "../../shared/sharedService";

const TakePicture: React.FC = () => {
  const TITLE = "Submit a Petrol Price";
  const imageElement = useRef<HTMLImageElement>();
  const [map, setMap] = useState<google.maps.Map>();
  const [present, dismiss] = useIonToast();
  const [isStepsCompleted, setIsStepsCompleted] = useState<any[]>([]);
  const [currentLocation, setCurrentLocation] = useState<google.maps.Marker>();
  const [isLocationAvailable, setIsLocationAvailable] = useState(true);
    
  useEffect(() => {
    console.log("use effefct");
    initMap();
  }, []);

  const isStepComplete = (step:string) => {   
    return isStepsCompleted.includes(step);
  }
  const removeStepComplete = (step:string) => { 
    let data = isStepsCompleted.filter(p => p !== step);  
    if(data)
    {     
      setIsStepsCompleted(data);
    }
  }
  const isValid = () => {
    return isStepsCompleted.includes("STEP1") &&  isStepsCompleted.includes("STEP2");
  }

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
    });
    removeStepComplete("STEP1");
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;
    var format = image.format;
    // var base64 = image.base64String;
    // Can be set to the src of an image now
    if (imageElement.current) {
      imageElement.current.src = imageUrl!;
      setIsStepsCompleted([...isStepsCompleted, "STEP1"]);
    }
  };
  const takeLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      setCurrentPosition,
      positionError,
      {
        enableHighAccuracy: false,
        timeout: 15000,
        maximumAge: 0,
      }
    );
  };
  const setCurrentPosition = (position: any) => {
    removeStepComplete("STEP2");
    if (position && position.coords) {
      globalService.RECENT_LONGITUTE = position.coords.longitude;
      globalService.RECENT_LATITUTE = position.coords.latitude;

      //Initialise Map
      centerMap(globalService.RECENT_LATITUTE, globalService.RECENT_LONGITUTE);

      setIsStepsCompleted([...isStepsCompleted, "STEP2"]);
      setIsLocationAvailable(true);
    }
  };
  const onTextChange = (e:any) => {    
       
    removeStepComplete("STEP2");
   
    if(e && e.detail && e.detail.value)
      {
        setIsStepsCompleted([...isStepsCompleted, "STEP2"]);
      }
  }
  const positionError = () => {
    present({
      color:"warning",
      buttons: [{ text: "Close", handler: () => dismiss() }],
      message: "We are unable to retrieve your location. Please enable location permission OR Enter your location manualy.",
    });
    setIsLocationAvailable(false);
  };
  function initMap() {
    let mapOptions = {
      center: { lat: -33.780486, lng: 150.9162774 },
      zoom: 12,
      zoomControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,
    };

    if (!map) {
      setMap(
        new google.maps.Map(
          document.getElementById("take-picture-google-map-container") as any,
          mapOptions
        )
      );
    }
  }
  function centerMap(lat: number, long: number) {
    let latLng = new google.maps.LatLng(lat, long);
    if (map) {
      map.panTo(latLng);

      if (!currentLocation) {
        //Create an instance of an marker
        setCurrentLocation(
          new google.maps.Marker({
            position: latLng,
            icon: "/assets/icon/person_pin.png",
            map: map,
            title: "You",
            animation: google.maps.Animation.BOUNCE,
          })
        );
      } else {
        //Set the current instance position
        currentLocation.setPosition(latLng);
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{TITLE}</IonTitle>
          <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{TITLE}</IonTitle>
          </IonToolbar>
        </IonHeader>   

        <IonCard color="secondary">
          <IonCardContent>
            <p>
              We greatly appreciate you taking the time to submit a fuel price.
              Your contribution is the heartbeat of this application. Your
              effort will determine the life of this application. In time, we hope to give back to you and our community.
            </p>
          </IonCardContent>
        </IonCard>


        <IonCard className="ion-text-center">      
        <IonItem color="light">
            <IonIcon hidden={!isStepComplete("STEP1")} color="success" icon={checkmarkCircleOutline} slot="start" />
            <IonLabel>Step 1</IonLabel>
          </IonItem>
          <IonCardContent>
            <IonButton
              fill={isStepComplete("STEP1") ? "solid" : "outline" }
              expand="block"
              onClick={takePicture.bind(this)}
              color={isStepComplete("STEP1") ? "success" : "primary" }
            >
              <IonIcon slot="start" icon={cameraOutline}></IonIcon>
              Take a Photo
            </IonButton>
          </IonCardContent>
          <img ref={imageElement as any} width="50%" />
        </IonCard>

        <IonCard className="ion-text-center">
        <IonItem color="light">
            <IonIcon hidden={!isStepComplete("STEP2")} color="success" icon={checkmarkCircleOutline} slot="start" />
            <IonLabel>Step 2</IonLabel>
          </IonItem>
          <IonCardContent>
            <IonButton
              fill={isStepComplete("STEP2") ? "solid" : "outline" }
              color={isStepComplete("STEP2") ? "success" : "primary" }
            
              expand="block"
              onClick={takeLocation.bind(this)}
            >
              <IonIcon slot="start" icon={pinOutline}></IonIcon>
                 {isLocationAvailable ? "Step 2. Use my current location" : "Step 2. Tell us your location"}
            </IonButton>           
            <IonItem hidden={isLocationAvailable} lines="full">
                 <IonTextarea onIonChange={onTextChange.bind(this)} placeholder="Tap here and tell us your location" rows={5} ></IonTextarea>
              </IonItem>          
          </IonCardContent>
          <div hidden={!isLocationAvailable} id="take-picture-google-map-container" className="take-picture-google-map"></div>
        </IonCard>

      </IonContent>
      
      <IonFooter hidden={!isValid()}>
        <IonToolbar color="light">
          <IonButton 
          expand="block" 
          shape="round"
          fill="solid"       
          >
            <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>{" "}
            Submit
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default TakePicture;
