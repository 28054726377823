import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { debug } from "console";
import {
  caretDown,
  caretUp,
  checkbox,
  checkmarkCircleOutline,
  warning,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ServiceStationPriceRespond } from "../../Models/ServiceStationPriceRespond";
import { IServiceStationRespond, ServiceStationRespond } from "../../Models/ServiceStationRespond";
import "./FuelPrice.css";

enum ModeType {
  Read = 0,
  Edit = 1,
  Create = 2,
  Delete = 3,
}

const FuelPrice: React.FC = () => {
  const [id, setId] = useState<string>();
  const [data, setData] = useState<ServiceStationRespond>();
  const [isLoading, SetIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [mode, setMode] = useState<ModeType>(ModeType.Read);
  const [actionDescription, setActionDescription] = useState<string>("Create");
  const [presentToast, dismissToast] = useIonToast();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get("id") || "");
  });


  useEffect(() => {
    console.log("Was DATA Change?");
   console.log(data);
  }, [data]);

  useEffect(() => {

    setActionDescription("New Fuel Price");

    if (id) {
      fetchData(id!);
      setMode(ModeType.Edit);
     
    } else {
      setMode(ModeType.Create);    
    }
  }, [id]);

  const fetchData = async (id: string) => {
    SetIsLoading(true);
    let response = await fetch(
      (window as any).env.API_SERVER + "/api/ServiceStations/" + id
    );

    let json = await response.json();
    let n_ssr: ServiceStationRespond = new ServiceStationRespond();
    n_ssr.populateFromJson(json.resultData[0]);
    setData(n_ssr);
    SetIsLoading(false);
  };
  
  const showToast = (title: string, body:string, color: string, icon:string) => {
    
    presentToast({
      color: color,   
      header: title,
      message: body,
      position: 'top',
      duration:3500,
      icon: icon 
    });

  }
  const onSubmit = async () => {
    
    //First validate the input is correct..
    if(data?.isPetrolPricesListValid())
    {

      SetIsLoading(true);
      let r = data.convertResponseToRequest();
      let response = await fetch(
        (window as any).env.API_SERVER + "/api/fuelprices/" + id,
        {
          method:"PUT",
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(r)
        }
      );
  
      let json = await response.json();
      
      // console.log(json);
    
      SetIsLoading(false);

      showToast("Thank you!","New Petrol price was submitted for " + data.ServiceStationName + ". Price will update shortly.","success", checkmarkCircleOutline )

      history.goBack();
    }
    else
    {
      showToast("Empty fields found.","Please make sure you've entered something on all fields.","warning", warning)
    }
  }
  const handleChange = (e:any)=>{
    let fuel_type = e.target.getAttribute("data-id");
    let value = e.detail.value;
    if(fuel_type)
    {
      
      let newPetrolPrice: ServiceStationPriceRespond[] = data!.PetrolPricesList.map(item => {

        if(item.PetrolType == fuel_type)
        {
          item.Price = value;
          return item as ServiceStationPriceRespond;
        }
        return item as ServiceStationPriceRespond;
      }) as ServiceStationPriceRespond[];

      //console.log("newPetrolPrice");
      //console.log(newPetrolPrice);
      //console.log(data?.PetrolPricesList);   
      data!.PetrolPricesList = newPetrolPrice;
      setData(prevstate => {
           // return {...prevstate, ...{ PetrolPricesList: newPetrolPrice }}
           return Object.assign(new ServiceStationRespond(), prevstate, data);
          });
      // setData( k =>({...data, PetrolPricesList: newPetrolPrice }));      
    }
 }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {data?.ServiceStationName} | {actionDescription}
          </IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonProgressBar
            hidden={!isLoading}
            id="progressbar"
            color="danger"
            type="indeterminate"
          ></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {data?.ServiceStationName} | {actionDescription}
            </IonTitle>
          </IonToolbar>       
        </IonHeader>

        <IonCard color="secondary">
          <IonCardContent>
            <p>
              We greatly appreciate you taking the time to submit a fuel price.
              Your contribution is the heartbeat of this application. Your
              effort will determine the life of this application. In time, we hope to give back to you and our community.
            </p>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardContent>
            <IonList>
              <IonListHeader lines="inset">
                <IonLabel>
                  <h2>
                    Enter a new Petrol Price for <strong>{data?.ServiceStationName}</strong>
                  </h2>
                </IonLabel>
              </IonListHeader>

              {data?.PetrolPricesList?.map((item) => {
                return (
                  <IonItem key={item.PetrolType} lines="full">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="6">
                          <IonLabel>
                            <IonLabel>
                              <h2>{item.PetrolType}</h2>
                              <IonBadge
                                color="light"
                                className="micro-text"
                              ></IonBadge>
                            </IonLabel>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="6">
                          {/* < className="ion-float-right"> */}
                            <IonChip color="dark" className="ion-float-right">
                              <IonInput                              
                                data-id={item.PetrolType}
                                placeholder="000.00"
                                type="number"
                                value={item.Price}
                                onIonChange={handleChange}
                              ></IonInput>
                            </IonChip>
                          {/* </IonLabel> */}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <IonButton expand="block" onClick={onSubmit} shape="round" fill="solid">
            <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>{" "}
            Submit
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default FuelPrice;
