class sharedService
{
    API_ENDPOINT: string = "<@API_URL@>";    
    RECENT_LONGITUTE!: number;
    RECENT_LATITUTE!: number;
    RECENT_LOCATION!: string;
    RECENT_LOCALITY!: string;
    DEFAULT_RADIUS: number = 10;
}

export const globalService = new sharedService();