import { caretDown, caretUp, removeOutline } from "ionicons/icons";

export class ServiceStationPriceRespond
{
    PetrolType!: string;
    Price!: number;
    FormattedPrice!: string;
    PriceIndex!: number;
    PriceColour!: string;
    Colour!: string;
    Modality!:string;
    ModalityIcon!: string;
    PriceDateTime!: string;
    PriceDateTimeFormatted!: string;      
    LastPriceUpdate!: string;
 
    populateServiceStationPriceRespond(data:any)
    {
        if(data)
        {
            this.PetrolType = data.petrolType;
            this.Price = data.price;
            this.FormattedPrice = data.formattedPrice
            this.PriceIndex = data.priceIndex;
            this.PriceDateTime = data.priceDateTime;
            this.PriceDateTimeFormatted = data.priceDateTimeFormatted;
            this.LastPriceUpdate = data.lastPriceUpdate;

            switch(parseInt(data.priceIndex.toString()))
            {
              case 1:
              case 2:
              case 3:
              case 4:                         
                this.Colour = "danger";
                this.PriceColour = "#eb445a";
                this.Modality = "High";
                this.ModalityIcon = caretUp;
                break;
              case 5:                
              case 6:
              case 7:
              case 8:
              case 9:   
              case 10:                     
                this.Colour = "warning";
                this.PriceColour  = "#ffc409";
                this.Modality = "Mid";
                this.ModalityIcon = removeOutline;
                break;               
              case 11:
              case 12:
                this.Colour = "success";
                this.PriceColour  = "#2dd36f";
                this.Modality = "Low";
                this.ModalityIcon = caretDown;
                break;
              default:
                this.Colour = "danger";
                this.PriceColour = "#eb445a";
                this.Modality = "High";
                break;
            }
        }

    }
}