import { CommonBase } from "./CommonBase";

export class SuburbPostcodeRespond //extends CommonBase
    {     
        Suburb!: string;
        Postcode!: string;
        Description!: string;
        State!: string;

        constructor(suburb:string, postcode:string, description:string, state:string)
        {
            this.Suburb = suburb;
            this.Postcode = postcode;
            this.Description = description;
            this.State = state;
        }
    }